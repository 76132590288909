.nav-button {
    color: #FFFFFF;
    font-size: inherit;
    position: relative;
    vertical-align: middle;
    height: 100px;
}

.nav-button::before,
button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

.draw {
    transition: color 0.25s;
}

.draw::before,
.draw::after {
    border: 5px solid transparent;
    width: 0;
    height: 0;
}

.draw::before {
    top: 0;
    left: 0;
}

.draw::after {
    bottom: 0;
    right: 0;
}

.draw:hover {
    color: #FFFFFF;
    background-color: #FFFFFF;
}

.draw:hover::before,
.draw:hover::after {
    width: 100%;
    height: 100%;
}

.draw:hover::before {
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
}

.draw:hover::after {
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
}